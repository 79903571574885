<template>
  <div class="form_box">
    <el-row>
      <div>{{questionInfo.content}}</div>
    </el-row>
    <el-row v-for="(item,index) in questionInfo.options" :key="item.id">
      <div>{{index+1}}、{{item.content}}</div>
    </el-row>
    <el-row></el-row>
    <el-row>
      <div>提示:{{questionInfo.hint}}</div>
    </el-row>
    <el-row>
      <div>解释:{{questionInfo.analysis}}</div>
    </el-row>
  </div>
</template>

<script>
  import api from '@/api/index'
export default {
    props:{
      operateBox:{
        type:Object,
        required:true
      }
    },
  name: 'check',
  data(){
    return{
      questionInfo:{}
    }
  },
  created(){
    if(this.operateBox.id!=null && this.operateBox.id!=''){
      this.getTestQinfo(this.operateBox.id)
    }
  },
  methods:{
    //根据试题id查看详情用于回显
    async getTestQinfo(id){
      try {
        let res =await api.testQinfoGet(id)
        if(res.code==200){
          this.questionInfo = res.data
        }
      }catch (e) {

      }
    },
  }
}
</script>

<style scoped lang="less">
.el-row {
  margin-bottom: 20px;
&:last-child {
   margin-bottom: 0;
 }
}
</style>
