<template>
  <div class="main_content" :class="isChildren ? 'childClass' : ''">
    <div class="title_box">
      <span></span>
      <h5 v-if="isChildren">试题列表 <p style="float: right;">试卷总分：{{ paperScore }}</p>
      </h5>
      <h5 v-else>试题管理</h5>
    </div>
    <div class="line" v-if="!isChildren"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input placeholder="试题内容" v-model="searchForm.content"></el-input>
        </div>
        <div class="input_box">
          <el-select v-model="searchForm.categoryId" clearable placeholder="试题标签">
            <el-option v-for="item in testLabel" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <el-select v-model="searchForm.typeId" clearable placeholder="题型">
            <el-option v-for="item in questionTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <el-button type="primary" class="el-icon-search" @click="srcBtn">搜索</el-button>
          <el-button type="warning" class="el-icon-document-checked" v-if="isChildren"
            @click="isShowList(false)">试卷预览</el-button>
        </div>
      </div>
      <div class="operate_btn" v-if="!isChildren">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addTest">添加</el-button>
        <el-button type="danger" :plain="true" icon="el-icon-delete" @click="deleteTest">批量删除</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border row-key="id" ref="multipleTable" @selection-change="handleSelectionChange"
        style="width: 100%" height="100%">
        <el-table-column type="selection" :reserve-selection="true" width="55" />
        <el-table-column fixed prop="id" label="ID" width="100" />
        <el-table-column prop="content" label="试题内容" />
        <el-table-column prop="categoryName" width="100" label="试题标签" />
        <el-table-column prop="questionType" label="试题题型" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.typeId == 1">单选题</span>
            <span v-if="scope.row.typeId == 2">多选题</span>
            <span v-if="scope.row.typeId == 3">问答题</span>
            <!--<span v-if="scope.row.typeId==4">问答</span>-->
          </template>
        </el-table-column>
        <el-table-column prop="score" label="试题分数" width="80" />
        <el-table-column prop="answer" label="答案" width="150" v-if="!isChildren">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.answer" :key="index">{{ index + 1 }}、{{ item }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="analysis" label="答案解释" width="230" v-if="!isChildren" />
        <el-table-column prop="hint" label="答案提示" width="160" v-if="!isChildren" />
        <el-table-column prop="createTime" label="创建时间" width="150" v-if="!isChildren" />
        <el-table-column prop="creatorName" label="创建人" width="110" v-if="!isChildren" />
        <el-table-column width="140" label="操作" v-if="!isChildren">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button type="primary" icon="el-icon-edit" circle @click="edit(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="试题详情" placement="top">
              <el-button type="warning" icon="el-icon-tickets" circle @click="check(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" icon="el-icon-delete" circle @click="delTestq(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->
    <!--添加-->
    <el-dialog :visible.sync="addDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <add-from :testLabel="testLabel" :key="timer" :operateBox="operateBox" @closed="closed"></add-from>
    </el-dialog>
    <!--试题详情-->
    <el-dialog :visible.sync="checkDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <check :operateBox="operateBox" @closed="closed" :key="timer"></check>
    </el-dialog>
  </div>
</template>

<script>
import AddFrom from './components/add-from'
import Check from './components/check'
import api from '@/api/index'
import { testQtype } from '@/utils/baseData'
export default {
  name: 'index',
  props: {
    isChildren: {
      type: Boolean,
    },
    selectRows: {
      type: Array,
    }
  },
  components: { Check, AddFrom },
  data() {
    return {
      testLabel: [],
      questionTypeList: testQtype,
      tableData: [],
      operateBox: {
        title: '添加试题',
        operate: 'add',
        id: null
      },
      searchForm: {
        content: '',
        typeId: null,
        categoryId: null,
        userId: null
      },
      pageBox: {
        pageSize: 10,
        total: 0,
        pageNum: 1
      },
      addDialog: false,
      checkDialog: false,
      delIds: [],
      timer: null,
      isShowQList: true,
      btnName: '试卷预览',

      //新增试卷时使用的参数
      paperScore: 0,
      questionInfo: null,

    }
  },
  created() {
    this.fetchUserPower()

  },
  mounted() {

  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          // if (this.$store.state.role !== 'admin') {
          //   // this.searchForm.communityIds = res.data.comId
          this.searchForm.userId = this.$store.state.userId
          // }
          this.testqGet(this.searchForm)
          this.getLabelList(this.searchForm)
        }
      } catch (e) {

      }
    },

    //获取试题标签列表
    async getLabelList(data) {
      let page = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        let res = await api.getExameTitileType(data, page)
        if (res.code == 200) {
          this.testLabel = res.rows
        }
      } catch (e) {
        console.error(e);
        this.$message.error(e)
      }
    },
    //获取试题列表
    async testqGet() {
      try {
        let res = await api.getTestqList(this.searchForm, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
          //复选方法
          if (Array.isArray(this.selectRows) && this.selectRows.length) {
            this.reselectFun(this.tableData, this.selectRows);
          }
        }
      } catch (e) {
        console.error(e);
        this.$message.error(e)
      }
    },
    srcBtn() {
      this.testqGet()
    },

    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.testqGet()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.testqGet()
    },
    //添加
    addTest() {
      this.operateBox = {
        title: '添加试题',
        operate: 'add',
        id: null
      }
      this.timer = new Date().getTime()
      this.addDialog = true
    },
    edit(row) {
      this.operateBox = {
        title: '编辑试题',
        operate: 'edit',
        id: row.id
      }
      this.timer = new Date().getTime()
      this.addDialog = true
    },
    //试题详情
    check(row) {
      this.operateBox = {
        title: '试题详情',
        operate: 'check',
        id: row.id
      }
      this.timer = new Date().getTime()
      this.checkDialog = true
    },
    //选中要删除的对应的id
    handleSelectionChange(val) {
      let ids = []
      let paperScores = null
      val.forEach(item => {
        ids.push(item.id)
        paperScores += item.score
      })
      this.paperScore = paperScores
      this.delIds = ids
      if (this.isChildren) {
        this.questionInfo = {
          paperQids: ids,
          sunScore: this.paperScore
        }
        this.$emit('getQuestions', this.questionInfo)
      }

    },
    deleteTest() {
      if (this.delIds == null) {
        this.$message.info('请选择删除项')
        return false
      }
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo(this.delIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    delTestq(row) {
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo([row.id])
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async delInfo(ids) {
      try {
        let res = await api.testqDel(ids)
        if (res.code == 200) {
          this.testqGet()
          this.$message({
            type: 'success',
            message: '删除成功'
          })
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    closed() {
      this.checkDialog = false
      this.addDialog = false
      this.testqGet()
    },
    isShowList(val) {
      this.$emit('showQlist', val)
    },

    // 复选
    reselectFun(dataArr, IDArr) {
      var _this = this
      _this.$nextTick(function () {
        dataArr.forEach(row => {
          if (IDArr.includes(String(row.id))) {
            _this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      });
    },
  }
}
</script>

<style scoped>
.el-pagination {
  text-align: center;
}

.childClass {
  width: calc(100% - 24px);
}
</style>
