<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="试题内容" prop="content">
        <el-input v-model="ruleForm.content"></el-input>
      </el-form-item>
      <el-form-item label="试题标签" prop="categoryId">
        <el-select v-model="ruleForm.categoryId">
          <el-option v-for="item in testLabel" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试题类型" prop="typeId">
        <el-select v-model="ruleForm.typeId" clearable placeholder="请选择">
          <el-option v-for="item in questionTypeList" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否是问卷" prop="questionnaire">
        <el-switch v-model="ruleForm.questionnaire" @change="isQuestion"></el-switch>
      </el-form-item>
      <el-form-item label="试题分数" prop="score">
        <el-input-number v-model="ruleForm.score" @change="handleChange" :min="1"></el-input-number>
      </el-form-item>
      <div v-if="ruleForm.typeId != 3">
        <div v-if="ruleForm.questionnaire">
          <el-form-item label="试题选项">
            <div v-for="(item, index) in options" :key="index">
              <el-col :span="20">
                <el-form-item :prop="item.content">
                  <el-input v-model="item.content"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <i class="el-icon-circle-plus-outline" @click="addOptions"></i>
                <i class="el-icon-delete" @click="delOptions(index)"></i>
              </el-col>
            </div>
          </el-form-item>
        </div>

        <div v-else>
          <el-form-item label="选项">
            <div v-for="(item, index) in options" :key="index">
              <el-col :span="13">
                <el-form-item :prop="item.content">
                  <el-input v-model="item.content"></el-input>
                </el-form-item>
              </el-col>
              <el-col class="line_style" :span="2">—</el-col>
              <el-col :span="5">
                <el-form-item :prop="item.answer">
                  <el-select v-model="item.answer">
                    <el-option label="是" value="1" />
                    <el-option label="否" value="0" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <i class="el-icon-circle-plus-outline" @click="addOptions"></i>
                <i class="el-icon-delete" @click="delOptions(index)"></i>
              </el-col>
            </div>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="答案解释" prop="analysis">
        <el-input v-model="ruleForm.analysis" type="textarea" :rows="2"></el-input>
      </el-form-item>
      <el-form-item label="答案提示" prop="hint">
        <el-input v-model="ruleForm.hint" type="textarea" :rows="2"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
        <el-button @click="cancel('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { testQtype } from '@/utils/baseData'
import api from '@/api/index'
import debounce from 'lodash/debounce'
export default {
  props: {
    testLabel: {
      type: Array,
      required: true
    },
    operateBox: {
      type: Object,
      required: true
    }
  },
  name: 'add-from',
  data() {
    return {
      ruleForm: {
        content: '',
        categoryId: '',
        typeId: '',
        questionnaire: false,
        score: '',
        analysis: '',
        hint: '',
        options: null
      },
      options: [{ answer: null, content: '' }],
      rules: {
        content: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        typeId: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        questionnaire: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        score: [
          { required: true, message: '只能输入数字' },
          {
            type: 'number',
            pattern: '/^[1-9]d{0,5}$/',
            message: '请输入整数，并且注意空格',
            trigger: 'blur',
            transform: (value) => Number(value)
          },
          {
            validator: (rule, value, callback) => {
              if ((value && value.toString().length > 3) || value < 0) {
                callback(new Error('不可超过3位数,不可小于0'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      },
      questionTypeList: testQtype
    }
  },
  created() {
    if (this.operateBox.id != null) {
      this.getTestQinfo(this.operateBox.id)
    }
  },
  methods: {
    // 根据试题id查看详情用于回显
    async getTestQinfo(id) {
      try {
        const res = await api.testQinfoGet(id)
        if (res.code == 200) {
          console.log(res)
          if (res.data.options && res.data.options.length > 0) {
            res.data.options.forEach((item) => {
              if (item.answer == true) {
                item.answer = '1'
              } else {
                item.answer = '0'
              }
            })
            this.options = res.data.options
          }
          this.ruleForm = res.data
        }
      } catch (e) { }
    },

    handleChange(value) {
      console.log(value)
    },
    isQuestion(val) {
      this.options = [{ answer: null, content: '' }]
    },
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          //选项校验
          if (this.ruleForm.typeId != 3) {
            for (let item of this.options) {
              if (item.content.trim() === '') {
                // 显示选项不能为空的错误提示
                this.$message.warning("选项不能存在空值");
                return;
              }
            }
          }
          if (this.ruleForm.typeId == 1) { //如果是单选题，那么有且只能有一个正确答案
            if (this.options.length < 2) {
              this.$message.warning("单选题选项个数不能少于2个");
              return;
            }
            if (!this.ruleForm.questionnaire && this.options.filter(item => item.answer === '1').length !== 1) {
              return this.$message.warning("单选题选项中必须有且只有一个正确答案");
            }
          }
          if (this.ruleForm.typeId == 2) { //如果是多选题，那么最少有两个正确答案
            if (this.options.length < 3) {
              this.$message.warning("多选题选项个数不能少于3个");
              return;
            }
            if (!this.ruleForm.questionnaire && this.options.filter(item => item.answer === '1').length < 2) {
              return this.$message.warning("多选题选项中必须有两个以上正确答案");
            }
          }

          this.ruleForm.options = this.options


          const data = JSON.parse(JSON.stringify(this.ruleForm))
          data.options.forEach((item) => {
            if (item.answer == '1') {
              item.answer = true
            } else {
              item.answer = false
            }
          })
          if (this.operateBox.operate == 'add') {
            this.questionAdd(data)
          } else {
            this.questionUpdate(data)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }, 500),

    // 新增试题
    async questionAdd(data) {
      try {
        const res = await api.testqAdd(data)
        if (res.code == 200) {
          this.$message.success('新增成功')
          this.$emit('closed')
        } else {
          this.$message.error('新增失败')
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },

    // 修改试题
    async questionUpdate(data) {
      console.log(data)
      try {
        const res = await api.testqUpdate(data)
        if (res.code == 200) {
          this.$message.success('修改成功')
          this.$emit('closed')
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },

    cancel() {
      this.$emit('closed')
    },
    // 添加试题选项
    addOptions() {
      this.options.push({ answer: null, content: '' })
    },
    // 删除试题选项
    delOptions(index) {
      if (this.options.length == 1) {
        this.$message.warning('至少保留一个选项')
        return false
      }
      this.options.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.line_style {
  text-align: center;
}

.el-icon-circle-plus-outline {
  font-size: 22px;
  color: #0bf3c2;
  cursor: pointer;
  margin: 0 10px;
}

.el-icon-delete {
  font-size: 22px;
  color: red;
  cursor: pointer;
}
</style>
